import { getRequest, patchRequest, putRequest } from '@/api'
import qs from 'qs'

/**
 * 我的资料
 * @returns {*|Promise<AxiosResponse<any>>}
 */
export function getUser() {
    return getRequest({
        url: '/uc/user/profile'
    })
}
export function getUseById(id) {
    return getRequest({
        url: `/uc/user/${id}`
    })
}

/**
 * 修改我的资料
 * @param data
 * @returns {*|Promise<AxiosResponse<any>>}
 */
export function updateUser(data) {
    return putRequest({
        url: '/uc/user/profile',
        data
    })
}

/**
 * 我的权限
 * @returns {*|Promise<AxiosResponse<any>>}
 */
export function getUserPermission() {
    return getRequest({
        url: '/uc/user/permission',
        timeout: 600000
    })
}

/**
 * 我的消息
 */
export function getUserMessage(params) {
    return getRequest({
        url: '/mc/message/mine',
        params,
        showLoading: false,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

/**
 * 置消息已读
 * @returns {*|Promise<AxiosResponse<any>>}
 * @param messageId
 */
export function seenUserMessage(messageId) {
    return patchRequest({
        url: '/mc/message/user/seen/' + messageId
    })
}

/**
 * 修改手机号
 * @returns {*|Promise<AxiosResponse<any>>}
 * @param data
 */
export function updateMobile(data) {
    return patchRequest({
        url: '/uc/user/security',
        data: data
    })
}

/**
 * 发送验证码
 * @param mobile 手机号
 * @returns {*|Promise<AxiosResponse<any>>}
 */
export function sendSmsCaptcha(mobile) {
    return getRequest({
        url: '/uc/captcha/sendSmsCaptcha',
        params: { mobile }
    })
}
